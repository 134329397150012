import styled from "styled-components";
import colorTokens from "@lmig/lmds-tokens/dist/json/core/color.json";
import typographyTokens from "@lmig/lmds-tokens/dist/json/core/typography.json";

const lmdsColors = colorTokens.lmig.color;
const { tealMedium, gray29, gray69, gray86, white } = lmdsColors; // split into two lines, as single-line destructuring is inexplicably causing undefined behaviour after build
const { bold } = typographyTokens.lmig.typography.weight;

export const StyledStepper = styled.div`
  display: flex;
  border-bottom: 1px solid ${gray86};
  justify-content: space-between;
`;

export const StyledStep = styled.div<{ $active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 295px;
  height: 64px;
  border-bottom: ${(props) =>
    props.$active ? `4px solid ${tealMedium}` : null};
  font-weight: ${(props) => (props.$active ? bold : "inherit")};
  color: ${(props) => (props.$active ? gray86 : gray69)};
`;

export const StyledIconContainer = styled.div`
  margin-right: 16px;
`;

export const StyledMobileStepper = styled.div`
  display: flex;
  height: 64px;
  width: 352px;
  border-left: 6px solid ${tealMedium};
  padding-left: 16px;
  font-weight: bold;
  align-items: center;
`;

export const StyledStepNumber = styled.div<{ $active?: boolean }>`
  border-radius: 100%;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  border: 1px solid ${(props) => (props.$active ? tealMedium : gray29)};
  color: ${(props) => (props.$active ? white : gray29)};
  background-color: ${(props) => (props.$active ? tealMedium : white)};
`;
