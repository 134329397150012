import { Content } from "@lmig/lmds-react";
import React from "react";
import ReactDOM from "react-dom/client";
import { MemoryRouter as Router } from "react-router-dom";
import App from "./App";
import Header from "./client/components/header/Header";
import { Footer } from "./client/components/footer/Footer";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const enableMocking = async () => {
  if (!process.env.REACT_APP_USE_MSW) {
    return;
  }

  const { worker } = await import("./client/mocks/browser");
  return worker.start();
};

enableMocking().then(() => {
  root.render(
    <React.StrictMode>
      <Header />
      <Router>
        <Content>
          <App />
        </Content>
      </Router>
      <Footer />
    </React.StrictMode>
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
