import React, { createContext, useContext, useReducer, FC } from "react";
import ValidateApp from "../components/authentication/ValidateApp";

enum ActionTypes {
  USER = "USER",
}

export type User = {
  firstName?: string | undefined;
  lastName?: string | undefined;
  birthYear?: string | undefined;
  incidentMonth?: string | undefined;
  loginParam?: string | undefined;
  verificationQuestions?: { [x: string]: string | null } | undefined;
  idParam?: string | undefined;
  authenticated?: boolean | undefined;
  expired?: boolean | undefined;
  notFound?: boolean | undefined;
  completed?: boolean | undefined;
  retryCount?: number | null | undefined;
  id?: string | null | undefined;
  brand?: string | null | undefined;
  disableCheckOption?: boolean | undefined;
};

export const initialState = {
  user: {
    firstName: undefined,
    lastName: undefined,
    birthYear: undefined,
    incidentMonth: undefined,
    loginParam: undefined,
    verificationQuestions: undefined,
    idParam: undefined,
    authenticated: undefined,
    expired: undefined,
    completed: undefined,
    retryCount: undefined,
    id: undefined,
    brand: undefined,
    disableCheckOption: true,
  },
};

interface AppContextInterface {
  user: User | undefined;
}

interface DispatchAction {
  type: ActionTypes;
  payload: AppContextInterface;
}

const reducer = (state: AppContextInterface, action: DispatchAction) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.USER: {
      return {
        ...state,
        user: { ...payload.user },
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};

const AppStateContext = createContext<{
  state: AppContextInterface;
  dispatch: React.Dispatch<DispatchAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

interface AppProviderProps {
  children: React.ReactNode;
}

const AppProvider: FC<AppProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <AppStateContext.Provider value={{ state, dispatch }}>
      {children}
    </AppStateContext.Provider>
  );
};

const useAppState = () => {
  const context = useContext(AppStateContext);
  if (context === undefined) {
    throw new Error("useAppState must be used within a AppProvider");
  }
  return context;
};

const getUser = (
  dispatch: React.Dispatch<DispatchAction>,
  loginParam: string | null,
  idParam: string | null,
) => {
  const app = ValidateApp.getInstance();
  app.init(loginParam, idParam).then((user) => {
    dispatch({
      type: ActionTypes.USER,
      payload: { ...initialState, user },
    });
  });
};

export default AppProvider;

export { ActionTypes, useAppState, AppStateContext, getUser };
