import { useMediaQuery } from "react-responsive";
import { StepProps } from "./components/Step";
import DesktopStepper from "./components/DesktopStepper";
import MobileStepper from "./components/MobileStepper";
import breakpointTokens from "@lmig/lmds-tokens/dist/json/core/breakpoint.json";
import { useAppStore } from "../../store/store";
import { PAYMENT_TYPES } from "../../store/store.types";
import { checkFlowDisabledSteps, checkSteps, ddeSteps } from "./steps-data";
import { useAppState } from "../../context/AppContext";
import { useStepContext } from "../../context/StepContext";
import { useEffect } from "react";

export type StepperDisplayProps = {
  activeStepNumber: number;
  steps: StepProps[];
};

const Stepper = () => {
  const { state } = useAppState();
  const { user } = state;

  const { setActiveFlow } = useStepContext();

  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${breakpointTokens.lmig.breakpoint.sm.max})`,
  });
  const { selectedPaymentType } = useAppStore();

  useEffect(() => {
    let selectedFlowSteps: StepProps[];

    if (user?.disableCheckOption) {
      selectedFlowSteps = checkFlowDisabledSteps;
    } else {
      selectedFlowSteps =
        selectedPaymentType === PAYMENT_TYPES.CHECK ? checkSteps : ddeSteps;
    }

    setActiveFlow(selectedFlowSteps);
  }, [selectedPaymentType, user?.disableCheckOption, setActiveFlow]);

  return isMobileWidth ? <MobileStepper /> : <DesktopStepper />;
};

export default Stepper;
