import { create } from "zustand";
import {
  PAYMENT_TYPES,
  BRANDS,
  PaymentType,
  Brand,
  Address,
} from "./store.types";

interface AppStore {
  selectedPaymentType: PaymentType;
  setSelectedPaymentType: (paymentType: PaymentType) => void;
  selectedBrand: Brand;
  setSelectedBrand: (brand: Brand) => void;
  payeeAddress: Address | undefined;
  setPayeeAddress: (address: Address) => void;
}

export const useAppStore = create<AppStore>()((set) => ({
  selectedPaymentType: PAYMENT_TYPES.DIRECT_DEPOSIT,
  setSelectedPaymentType: (paymentType: PaymentType) =>
    set({ selectedPaymentType: paymentType }),
  selectedBrand: BRANDS.LIBERTY_MUTUAL,
  setSelectedBrand: (brand: Brand) => set({ selectedBrand: brand }),
  payeeAddress: undefined,
  setPayeeAddress: (address: Address) => set({ payeeAddress: address }),
}));
