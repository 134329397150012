import { StyledStepper } from "../Stepper.styles";
import Step from "./Step";
import { useStepContext } from "../../../context/StepContext";

const DesktopStepper = () => {
  const { stepState } = useStepContext();
  const { activeFlow, activeStepNumber } = stepState;

  return (
    <StyledStepper>
      {activeFlow.map((step) => (
        <Step key={step.order} activeStep={activeStepNumber} {...step} />
      ))}
    </StyledStepper>
  );
};

export default DesktopStepper;
