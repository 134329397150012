import React, {
  createContext,
  useContext,
  FC,
  useState,
  useCallback,
} from "react";
import { StepProps } from "../components/stepper/components/Step";
import { ddeSteps } from "../components/stepper/steps-data";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../utilities/consts";

export type Step = {
  activeFlow: StepProps[];
  activeStepNumber: number;
};

export const initialState: Step = {
  activeFlow: ddeSteps,
  activeStepNumber: 1,
};

const StepContext = createContext<{
  stepState: Step;
  setActiveFlow: (activeFlow: StepProps[]) => void;
  getActiveStep: () => StepProps;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  hasPrevStep: () => boolean;
  resetToPaymentSelection: () => void;
}>({
  stepState: initialState,
  setActiveFlow: () => {},
  getActiveStep: () => initialState.activeFlow[0],
  goToNextStep: () => {},
  goToPreviousStep: () => {},
  hasPrevStep: () => false,
  resetToPaymentSelection: () => {},
});

interface StepProviderProps {
  children: React.ReactNode;
}

const StepProvider: FC<StepProviderProps> = ({ children }) => {
  const [stepState, setStepState] = useState<Step>(initialState);
  const navigate = useNavigate();

  const getActiveStep = useCallback(() => {
    return stepState.activeFlow.find(
      (step) => step.order === stepState.activeStepNumber,
    ) as StepProps;
  }, [stepState]);

  const goToNextStep = useCallback(() => {
    const activeStep = getActiveStep();
    if (activeStep && activeStep.nextLocation) {
      setStepState((prevState) => ({
        ...prevState,
        activeStepNumber: prevState.activeStepNumber + 1,
      }));
      navigate(activeStep.nextLocation);
    }
  }, [getActiveStep, navigate]);

  const goToPreviousStep = useCallback(() => {
    const activeStep = getActiveStep();
    if (activeStep && activeStep.prevLocation) {
      setStepState((prevState) => ({
        ...prevState,
        activeStepNumber: prevState.activeStepNumber - 1,
      }));
      navigate(activeStep.prevLocation);
    }
  }, [getActiveStep, navigate]);

  const hasPrevStep = useCallback(() => {
    const activeStep = getActiveStep();
    return activeStep.prevLocation ? true : false;
  }, [getActiveStep]);

  const resetToPaymentSelection = useCallback(() => {
    setStepState((prevState) => ({
      ...prevState,
      activeFlow: ddeSteps,
      activeStepNumber: 2,
    }));
    navigate(PAGES.SELECT_PAYMENT_TYPE);
  }, [navigate]);

  const setActiveFlow = useCallback((activeFlow: StepProps[]) => {
    setStepState((prevState) => ({
      ...prevState,
      activeFlow,
    }));
  }, []);

  return (
    <StepContext.Provider
      value={{
        stepState,
        setActiveFlow,
        getActiveStep,
        goToNextStep,
        goToPreviousStep,
        hasPrevStep,
        resetToPaymentSelection,
      }}
    >
      {children}
    </StepContext.Provider>
  );
};

const useStepContext = () => {
  const context = useContext(StepContext);
  if (context === undefined) {
    throw new Error("useStepState must be used within a StepProvider");
  }
  return context;
};

export default StepProvider;

export { useStepContext };
