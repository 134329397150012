import { StyledMobileStepper, StyledStepNumber } from "../Stepper.styles";
import { useStepContext } from "../../../context/StepContext";

const MobileStepper = () => {
  const { stepState, getActiveStep } = useStepContext();
  const { activeFlow, activeStepNumber } = stepState;

  return (
    <StyledMobileStepper>
      <StyledStepNumber $active={true}>{activeStepNumber}</StyledStepNumber>
      {`Step ${activeStepNumber} of ${activeFlow.length}: ${getActiveStep().text}`}
    </StyledMobileStepper>
  );
};

export default MobileStepper;
