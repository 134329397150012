import { PAGES } from "../../utilities/consts";
import { StepProps } from "./components/Step";

export const stepLabels = {
  IDENTITY_VERIFICATION: "Identity Verification",
  PAYMENT_METHOD: "Payment Method",
  AGREEMENTS: "Agreements",
  FINANCIAL_INFORMATION: "Financial Information",
  PAYMENT_LOCATION: "Payment Location",
} as const;
export type StepLabel = (typeof stepLabels)[keyof typeof stepLabels];

export const ddeSteps: StepProps[] = [
  {
    text: stepLabels.IDENTITY_VERIFICATION,
    order: 1,
    nextLocation: `/${PAGES.SELECT_PAYMENT_TYPE}`,
  },
  {
    text: stepLabels.PAYMENT_METHOD,
    order: 2,
    nextLocation: `/${PAGES.TERMS}`,
  },
  {
    text: stepLabels.AGREEMENTS,
    order: 3,
    prevLocation: `/${PAGES.SELECT_PAYMENT_TYPE}`,
    nextLocation: `/${PAGES.ACCOUNT_CAPTURE}`,
  },
  { text: stepLabels.FINANCIAL_INFORMATION, order: 4 },
];

export const checkFlowDisabledSteps: StepProps[] = [
  {
    text: stepLabels.IDENTITY_VERIFICATION,
    order: 1,
    nextLocation: `/${PAGES.TERMS}`,
  },
  {
    text: stepLabels.AGREEMENTS,
    order: 2,
    nextLocation: `/${PAGES.ACCOUNT_CAPTURE}`,
  },
  { text: stepLabels.FINANCIAL_INFORMATION, order: 3 },
];

export const checkSteps: StepProps[] = [
  {
    text: stepLabels.IDENTITY_VERIFICATION,
    order: 1,
    nextLocation: `/${PAGES.SELECT_PAYMENT_TYPE}`,
  },
  {
    text: stepLabels.PAYMENT_METHOD,
    order: 2,
    nextLocation: `/${PAGES.CONFIRM_ADDRESS}`,
  },
  {
    text: stepLabels.PAYMENT_LOCATION,
    order: 3,
    prevLocation: `/${PAGES.SELECT_PAYMENT_TYPE}`,
  },
];
