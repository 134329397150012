import { StyledCardsContainer } from "./IncorrectAddressOptions.styles";
import { OptionCard } from "./OptionCard";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../../../utilities/consts";
import { useStepContext } from "../../../context/StepContext";
import { useAppStore } from "../../../store/store";
import { PAYMENT_TYPES } from "../../../store/store.types";

export const IncorrectAddressOptions = () => {
  const navigate = useNavigate();
  const { UPDATE_ADDRESS_CONFIRMATION } = PAGES;

  const stepContext = useStepContext();
  const { setSelectedPaymentType } = useAppStore();

  return (
    <StyledCardsContainer>
      <OptionCard
        heading="I'd like to update my address in order to receive a paper check."
        buttonText="Notify my Liberty representative"
        action={() => navigate(`/${UPDATE_ADDRESS_CONFIRMATION}`)}
      />
      <OptionCard
        heading="I'd like to choose Direct Deposit."
        buttonText="Go to Direct Deposit"
        action={() => {
          setSelectedPaymentType(PAYMENT_TYPES.DIRECT_DEPOSIT);
          stepContext.resetToPaymentSelection();
        }}
      />
    </StyledCardsContainer>
  );
};
